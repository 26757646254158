import React, { useEffect, useState } from 'react';
import { graphql, PageProps } from 'gatsby';

import PostLayout from '../components/postLayout';
import Seo from '../components/seo';
import { ContactPageQuery } from '../../types/graphql-types';
import { AuthProvider, useAuth } from '../hooks/AuthProvider';
import { addContact, onAuthStateChanged, signIn } from '../plugins/firebase';

type UserInput = {
  name: string;
  email: string;
  message: string;
};

type InputError = {
  name: 'name' | 'email' | 'message';
  message: string;
};

const ContactPage: React.FC<PageProps<ContactPageQuery>> = ({
  data,
  location,
}) => {
  const siteTitle = data.site.siteMetadata.title;
  const [user, setUser] = useState<
    | {
        uid: string;
      }
    | undefined
  >();
  const [userInput, setUserInput] = useState<UserInput>({
    name: '',
    email: '',
    message: '',
  });
  const [resultMessage, setResultMessage] = useState<string | undefined>();
  const [inputError, setInputError] = useState<InputError[]>([]);

  useEffect(() => {
    signIn();
    onAuthStateChanged((user) => {
      if (user) {
        setUser({ uid: user.uid });
      }
    });
  }, []);

  const onChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const target = event.target;
    const name: string = target.name;
    const value: string = target.value.replace(/(<([^>]+)>)/gi, '');

    setInputError((inputError) => {
      return inputError.filter((error) => error.name !== name);
    });

    setUserInput((userInput) => ({ ...userInput, [name]: value }));
  };

  const validateEmail = (email: string): InputError | undefined => {
    // 必須入力チェック
    if (email === undefined || email === '') {
      return {
        name: 'email',
        message: 'メールアドレスを入力してください',
      };
    }
    // 形式チェック
    const reg = new RegExp(
      "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$"
    );
    if (!reg.test(email)) {
      return {
        name: 'email',
        message: 'メールアドレスの形式が正しくありません',
      };
    }
  };

  const onSendHandler = async () => {
    // init
    const { name, email, message } = userInput;
    setResultMessage(undefined);
    setInputError([]);

    // preconditon-check
    const newInputError: InputError[] = [];
    if (name === undefined || name === '') {
      newInputError.push({ name: 'name', message: 'お名前を入力してください' });
    }
    if (validateEmail(email)) {
      newInputError.push(validateEmail(email));
    }
    if (message === undefined || message === '') {
      newInputError.push({
        name: 'message',
        message: 'お問い合わせ内容を入力してください',
      });
    }
    if (newInputError.length > 0) {
      setInputError(newInputError);
      return;
    }

    // send action
    try {
      await addContact({
        name: userInput.name,
        email: userInput.email,
        message: userInput.message,
        createdBy: user.uid,
      });
    } catch (error) {
      // console.log(error);
      setResultMessage('お問い合わせの送信に失敗しました。');
      return;
    }

    // reset userInput
    setUserInput({
      name: '',
      email: '',
      message: '',
    });
    setResultMessage('お問い合わせを送信しました。');
  };

  // React.useEffect(() => {
  //   console.log(userInput);
  // }, [userInput]);

  // React.useEffect(() => {
  //   console.log(user);
  // }, [user]);

  return (
    <PostLayout location={location} title={siteTitle}>
      <Seo title="お問い合わせ" />
      <article itemScope itemType="http://schema.org/Article">
        <header>
          {/* <!--Title--> */}
          <div className="text-center pt-16 md:pt-32">
            <h1 className="font-bold break-normal text-3xl md:text-5xl">
              お問い合わせ
            </h1>
          </div>
          {/* <!--image--> */}
          <div
            className="container w-full max-w-6xl mx-auto bg-white bg-cover mt-8 rounded"
            style={{
              backgroundImage:
                "url('https://source.unsplash.com/collection/1118905/')",
              height: '20vh',
            }}
          />
        </header>

        {/* <!--Container--> */}
        <div className="container max-w-5xl mx-auto -mt-32">
          <div className="mx-0 sm:mx-6">
            <div className="bg-white w-full p-8 md:p-24 text-xl md:text-2xl text-gray-800 leading-normal">
              {/* <!--/ Post Content--> */}
              <section>
                <div className="mt-2 text-gray-800">
                  {resultMessage && (
                    <div className="text-center">
                      <p className="text-gray-400 dark:text-gray-400">
                        {resultMessage}
                      </p>
                    </div>
                  )}
                  <div className="m-7">
                    <div className="mb-6">
                      <label
                        htmlFor="name"
                        className="block mb-2 text-sm text-gray-600 dark:text-gray-400">
                        お名前
                      </label>
                      <input
                        type="text"
                        name="name"
                        id="name"
                        placeholder="山田　太郎"
                        value={userInput.name}
                        onChange={onChangeHandler}
                        required
                        className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500"
                      />
                      {inputError.find((error) => error.name === 'name') && (
                        <span className="text-sm text-red-500">
                          {
                            inputError.find((error) => error.name === 'name')
                              .message
                          }
                        </span>
                      )}
                    </div>
                    <div className="mb-6">
                      <label
                        htmlFor="email"
                        className="block mb-2 text-sm text-gray-600 dark:text-gray-400">
                        メールアドレス
                      </label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        placeholder="you@example.com"
                        value={userInput.email}
                        onChange={onChangeHandler}
                        required
                        className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500"
                      />
                      {inputError.find((error) => error.name === 'email') && (
                        <span className="text-sm text-red-500">
                          {
                            inputError.find((error) => error.name === 'email')
                              .message
                          }
                        </span>
                      )}
                    </div>
                    <div className="mb-6">
                      <label
                        htmlFor="message"
                        className="block mb-2 text-sm text-gray-600 dark:text-gray-400">
                        お問い合わせ内容
                      </label>

                      <textarea
                        rows={5}
                        name="message"
                        id="message"
                        placeholder="お問い合わせ内容"
                        onChange={onChangeHandler}
                        value={userInput.message}
                        required
                        className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500">
                        {/* {userInput.message} */}
                      </textarea>
                      {inputError.find((error) => error.name === 'message') && (
                        <span className="text-sm text-red-500">
                          {
                            inputError.find((error) => error.name === 'message')
                              .message
                          }
                        </span>
                      )}
                    </div>
                    <div className="mb-6">
                      <button
                        type="submit"
                        onClick={onSendHandler}
                        className="w-full px-3 py-4 text-white bg-indigo-500 rounded-md focus:bg-indigo-600 focus:outline-none">
                        送信する
                      </button>
                    </div>
                    <p
                      className="text-base text-center text-gray-400"
                      id="result"></p>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </article>
    </PostLayout>
  );
};

export default ContactPage;

export const pageQuery = graphql`
  query ContactPage {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
